import Router from './assets/scripts/Router';

(function () {
    let initialSectionName = "Work";

    let currentSectionName = initialSectionName;
    let navItems = document.querySelectorAll('navigation ul li');
    let navList = document.querySelector('navigation ul');
    let contentContainer = document.querySelector("section#main");
    let sections = document.querySelectorAll("section#main > section");

    let listenOnce = (node, eventName, func) => {
        node.addEventListener(eventName, function _l(e) {
            node.removeEventListener(eventName, _l);
            func(e);
        });
    };

    let moveForward = () => {
        let prev = document.querySelector('navigation ul li.prev');
        let active = document.querySelector('navigation ul li.active');
        let next = document.querySelector('navigation ul li.next');

        let prevContent = document.querySelector('section.prev');
        let activeContent = document.querySelector('section.active');
        let nextContent = document.querySelector('section.next');

        let p1 = new Promise((s) => {
            listenOnce(prev, 'animationend', () => {
                prev.classList.remove('prev-to-active');
                prev.classList.remove('prev');
                prev.classList.add('active');
                s();
            });
            prev.classList.add('prev-to-active');
        });
        let p2 = new Promise(s2 => {
            listenOnce(active, 'animationend', () => {
                active.classList.remove('active-to-next');
                active.classList.remove('active');
                active.classList.add('next');
                s2();
            });
            active.classList.add('active-to-next');
        });
        let p3 = new Promise(s => {
            listenOnce(next, 'animationend', () => {
                // next.remove();
                // navList.prepend(next);
                listenOnce(next, 'animationend', () => {
                    next.classList.remove('out-to-prev');
                    next.classList.remove('next');
                    next.classList.add('prev');
                    s();
                });
                next.classList.remove('next-to-out');
                next.classList.add('out-to-prev');
            });
            next.classList.add('next-to-out');
        });

        let p4 = new Promise(s => {
            prevContent.classList.add('prev-to-active');
            listenOnce(prevContent, 'animationend', () => {
                prevContent.classList.remove('prev-to-active');
                prevContent.classList.remove('prev');
                prevContent.classList.add('active');
                s();
            });
        });
        let p5 = new Promise(s2 => {
            activeContent.classList.add('active-to-next');
            listenOnce(activeContent, 'animationend', () => {
                activeContent.classList.remove('active-to-next');
                activeContent.classList.remove('active');
                activeContent.classList.add('next');
                s2();
            });
        });
        let p6 = new Promise(s => {
            nextContent.classList.add('next-to-out');
            listenOnce(nextContent, 'animationend', () => {
                nextContent.classList.remove('next-to-out');
                // nextContent.remove();
                // contentContainer.prepend(nextContent);
                nextContent.classList.add('out-to-prev');
                listenOnce(nextContent, 'animationend', () => {
                    nextContent.classList.remove('out-to-prev');
                    nextContent.classList.remove('next');
                    nextContent.classList.add('prev');
                    s();
                });
            });
        });

        return Promise.all([p1, p2, p3, p4, p5, p6]);
    };

    let moveBackward = () => {
        let prev = document.querySelector('navigation ul li.prev');
        let active = document.querySelector('navigation ul li.active');
        let next = document.querySelector('navigation ul li.next');

        let prevContent = document.querySelector('section.prev');
        let activeContent = document.querySelector('section.active');
        let nextContent = document.querySelector('section.next');

        let p1 = new Promise(s => {
            prev.classList.add('prev-to-out');
            listenOnce(prev, 'animationend', () => {
                prev.classList.remove('prev-to-out');
                // prev.remove();
                // navList.append(prev);
                prev.classList.add('out-to-next');
                listenOnce(prev, 'animationend', () => {
                    prev.classList.remove('out-to-next');
                    prev.classList.remove('prev');
                    prev.classList.add('next');
                    s();
                });
            });
        });

        let p2 = new Promise(s => {
            active.classList.add('active-to-prev');
            listenOnce(active, 'animationend', () => {
                active.classList.remove('active-to-prev');
                active.classList.remove('active');
                active.classList.add('prev');
                s();
            });
        });

        let p3 = new Promise(s => {
            next.classList.add('next-to-active');
            listenOnce(next, 'animationend', () => {
                next.classList.remove('next-to-active');
                next.classList.remove('next');
                next.classList.add('active');
                s();
            });
        });

        let p4 = new Promise(s => {
            prevContent.classList.add('prev-to-out');
            listenOnce(prevContent, 'animationend', () => {
                prevContent.classList.remove('prev-to-out');
                // prevContent.remove();
                // contentContainer.append(prevContent);
                prevContent.classList.add('out-to-next');
                listenOnce(prevContent, 'animationend', () => {
                    prevContent.classList.remove('out-to-next');
                    prevContent.classList.remove('prev');
                    prevContent.classList.add('next');
                    s();
                });
            });
        });

        let p5 = new Promise(s => {
            activeContent.classList.add('active-to-prev');
            listenOnce(activeContent, 'animationend', () => {
                activeContent.classList.remove('active-to-prev');
                activeContent.classList.remove('active');
                activeContent.classList.add('prev');
                s();
            });
        });

        let p6 = new Promise(s => {
            nextContent.classList.add('next-to-active');
            listenOnce(nextContent, 'animationend', () => {
                nextContent.classList.remove('next-to-active');
                nextContent.classList.remove('next');
                nextContent.classList.add('active');
                s();
            });
        });

        return Promise.all([p1, p2, p3, p4, p5, p6]);

    };

    let disabledNavItemClick = (e) => {
        console.dir('disabled');
        e.preventDefault();
    };

    let disableNavClick = () => {
        [...navItems].map(i => i.addEventListener('click', disabledNavItemClick));
        [...navItems].map(i => i.removeEventListener('click', onNavItemClick));
    };
    let enableContentSections = () => {
        document.querySelector("#main").style.display = "block";
        document.querySelector("#privacy-policy").style.display = "none";
    };
    let enablePrivacy = () => {
        document.querySelector("#main").style.display = "none";
        document.querySelector("#privacy-policy").style.display = "block";
    };

    let onNavItemClick = (e) => {
        enableContentSections();

        console.dir(e.target);
        e.preventDefault();
        let linkTarget = e.currentTarget.querySelector('a').attributes.href.nodeValue;
        console.dir(linkTarget);
        // location.href = linkTarget;
        history.pushState({}, "", linkTarget);
        if (e.currentTarget.classList.contains("next")) {
            disableNavClick();
            moveBackward()
                .then(() => {
                    registerNavClick();
                });
        }
        if (e.currentTarget.classList.contains("prev")) {
            disableNavClick();
            moveForward()
                .then(() => {
                    registerNavClick();
                });
        }
    };

    let onSectionClick = e => {
        if (e.currentTarget.classList.contains("next")) {
            moveBackward();
        }
        if (e.currentTarget.classList.contains("prev")) {
            moveForward();
        }
    };

    let registerNavClick = () => {
        [...navItems].map(i => i.addEventListener('click', onNavItemClick));
        //  comment the following two lines to disable section click / /* for comments with more than 1 line */
        [...sections]
            .map(i => i.addEventListener('click', onSectionClick));
    };

    registerNavClick();


    let burger = document.querySelector(".nav-burger");
    let nav = document.querySelector("navigation");
    burger.addEventListener("click", () => {

        if (nav.classList.contains("closed")) {
            nav.classList.remove("closed");
        } else {
            nav.classList.add("closed");
        }
    });

    let sectionWorkContent = document.querySelector('#section-work .work-items');
    let workDetailContent = document.querySelector('#work-detail-content');

    let workitems = [...document.querySelectorAll('.work-item')];
    workitems.map(wi => {
        let titlepic = wi.querySelector('.work-item-titlepic');
        let content = wi.querySelector('.work-item-content');
        titlepic.addEventListener('click', () => {
            workDetailContent.innerHTML = content.innerHTML;
            sectionWorkContent.classList.add("fadeOut");
            sectionWorkContent.classList.remove("fadeIn");
            workDetailContent.classList.add("fadeIn");
            workDetailContent.classList.remove("fadeOut");
            window.scrollTo({'behavior': 'smooth', 'left': 0, 'top': 0});
        });
    });

    let workLink = document.querySelector("a[section='work']");
    workLink.addEventListener("click", () => {
        workDetailContent.classList.add("fadeOut");
        sectionWorkContent.classList.add("fadeIn");
        workDetailContent.classList.remove("fadeIn");
        sectionWorkContent.classList.remove("fadeOut");
    });

    let moveToSection = (sectionTitle) => {
        enableContentSections();
        let section = document.querySelector("section#section-" + sectionTitle);
        if (section) {
            if (section.classList.contains("prev")) {
                moveForward();
            }
            if (section.classList.contains("next")) {
                moveBackward();
            }
        }
    };

    let router = new Router();
    router.get('/information', (req) => {
        moveToSection('information');
    });
    router.get('/', (req) => {
        moveToSection('work');
    });
    router.get('/work', (req) => {
        moveToSection('work');
    });
    router.get('/journal', (req) => {
        moveToSection('journal');
    });
    router.get('/privacy-policy', (req) => {
        enablePrivacy();
    });
    router.init();

    let timeWidget = document.querySelector("#timeWidget");
    let nowAtBrowser = new Date();
    let nowAtBrowserSeconds = nowAtBrowser.getHours() * 60 * 60 + nowAtBrowser.getMinutes() * 60 + nowAtBrowser.getSeconds();
    let nowAtOffset = new Date();
    let nowAtOffsetSeconds = nowAtOffset.getHours() * 60 * 60 + nowAtOffset.getMinutes() * 60 + nowAtOffset.getSeconds() + parseInt(timeWidget.attributes.offsetHours.nodeValue) * 3600;

    let finalDate = new Date(1970, 0, 1);
    finalDate.setSeconds(nowAtOffsetSeconds);
    // var ampm = finalDate.getHours() >= 12 ? 'PM' : 'AM';
    let minutesLeadingZero = finalDate.getMinutes() < 10 ? '0' : '';

    timeWidget.innerHTML = (finalDate.getHours()) + ":" + minutesLeadingZero + finalDate.getMinutes();


    let footer = document.querySelector("footer");
    sections[0].append(footer.cloneNode(true));
    sections[1].append(footer.cloneNode(true));
    sections[2].append(footer.cloneNode(true));
    footer.remove();

    [...document.querySelectorAll('.icon-jump-top')].map((jumplink) => {
        jumplink.addEventListener('click', (e) => {
            e.preventDefault();
            window.scrollTo({'behavior': 'smooth', 'left': 0, 'top': 0});
        });
    });

    // custom cursor work items
    [...document.querySelectorAll('.work-item-titlepic')].map((titlepic) => {
        let mouseMoveListener = (e) => {
            document.querySelector("#customCursor").style.position = 'absolute';
            document.querySelector("#customCursor").style['user-select'] = 'none';
            document.querySelector("#customCursor").style.top = e.pageY + 2 + 'px';
            document.querySelector("#customCursor").style.left = e.pageX + 2 + 'px';
        };

        titlepic.addEventListener('mouseover', () => {
            document.querySelector('body').style.cursor = "none";
            // console.dir(document.querySelector('body'));
            titlepic.addEventListener('mousemove', mouseMoveListener);
            document.querySelector("#customCursor").style.visibility = 'visible';
        });
        titlepic.addEventListener('mouseout', () => {
            document.querySelector('body').style.cursor = "";
            document.querySelector("#customCursor").style.visibility = 'hidden';
            titlepic.removeEventListener('mousemove', mouseMoveListener);
        });
    });
})();
